import React, { useContext } from "react";

import MenuContext from "context/MenuContext";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { dispensaryHref } from "utils/dispensaryUrls";

import CoinList from "components/CoinList";

const CategoryCarousel: React.FC<{
  className?: string;
  dispensaryRetailType?: string;
  dispensarySlug: string;
  filterable?: boolean;
}> = ({ className, dispensarySlug, filterable, dispensaryRetailType }) => {
  const countryCode = useDomainCountryCode();

  const {
    selectors: { categories, loading },
  } = useContext(MenuContext);

  const hrefLink = dispensaryHref({
    additionalPath: "/menu",
    countryCode,
    retailType: dispensaryRetailType,
    slug: dispensarySlug,
  });

  return (
    <CoinList
      className={className}
      coins={categories}
      filterable={filterable}
      hrefLink={hrefLink}
      hrefProductCatQueryKey="product_category[]"
      isLoading={loading}
      title="Shop by category"
    />
  );
};

export default CategoryCarousel;
